import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchFilterPipes } from './search-filter-pipes';
import { UrlifyPipe } from './urlify.pipe';
import { SearchPipe } from './custome-search.pipe';


@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [SearchFilterPipes, UrlifyPipe, SearchPipe],
    exports: [SearchFilterPipes, UrlifyPipe, SearchPipe]
})
export class SharedPipesModule { }
