import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "../../environments/environment";
import { Observable, throwError } from "rxjs";
import { Login } from "../login/login.model";
import { retry, catchError } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";

@Injectable({
    providedIn: "root",
})
export class AuthService {
    apiURL = environment.apiURL;
    public httpOptions: any;

    constructor(
        private http: HttpClient,
        private router: Router,
        private toastr: ToastrService
    ) {}
    private setHeaders(params = null) {
        const accessToken = localStorage.getItem("userToken");
        const reqData = {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        };
        if (params) {
            Object.keys(params).map((k) => {
                reqData.headers[k] = params[k];
            });
        }
        return reqData;
    }
    login(data): Observable<Login[]> {
        return this.http
            .post<Login[]>(this.apiURL + "auth/backend-signin", {
                email: data.email,
                password: data.password,
                ip_address: localStorage.getItem("ip_address") || null,
                login_via: "Admin_panel",
            })
            .pipe(retry(1), catchError(this.handleError));
    }

    handleError(error) {
        let errorMessage = {};
        if (error.error instanceof ErrorEvent) {
            errorMessage = { message: error.error.message };
        } else {
            errorMessage = {
                status: error.status,
                message: error.error.message,
            };
        }
        return throwError(errorMessage);
    }

    clearStorage() {
        localStorage.removeItem("userToken");
        localStorage.clear();
        this.router.navigate(["/login"]);
    }

    checkUserLogin() {
        const checkUser = localStorage.getItem("userToken");
        if (checkUser) {
            this.router.navigate(["/dashboard"]);
        } else {
            this.clearStorage();
            this.router.navigate(["/login"]);
            return false;
        }
    }

    forgotPassword(data) {
        return this.http.post(
            `${environment.apiURL}auth/backend-forgot-password`,
            {
                email: data.email,
            }
        );
    }

    resetPassword(data, token) {
        let headers: any = {
            source_code: "LT",
        };
        return this.http.post(
            `${environment.apiURL}auth/reset-password`,
            data,
            this.setHeaders(headers)
        );
    }
}
