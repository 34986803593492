import { Component, OnInit } from '@angular/core';
import { AuthService } from './services/auth.service';
import { environment } from '../environments/environment';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    environment = environment;
    
    constructor(
        private authService: AuthService,
        private router: Router,
        private httpClient: HttpClient
    ) {

    }

    ngOnInit() {
        this.getIpAddress();  
    } 

    getIpAddress(){
        var url = 'https://geolocation-db.com/json/'
        this.httpClient.get(url).subscribe((res:any)=>{
        localStorage.setItem('ip_address', res.IPv4)
        })
    }

}
