import { Pipe, PipeTransform } from "@angular/core";
@Pipe({
    name: "urlify",
})
export class UrlifyPipe implements PipeTransform {
    transform(str: string): string {
        let exp =
            /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
        return str ? str.replace(exp, "<a target='_blank' href='$1'>$1</a>") : str;
    }
}
