import { CommonModule, DecimalPipe } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './shared';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { NgSelectModule } from '@ng-select/ng-select';
import { UiSwitchModule } from 'ngx-toggle-switch';
import { ChartsModule, ChartsModule as Ng2Charts } from 'ng2-charts';
import { NgxPermissionsModule } from 'ngx-permissions';
import { RecaptchaModule } from 'ng-recaptcha';
import { GlobalErrorHandler } from './global-error-handler';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ScrollTopService } from './services/scrolltop.service';
import { CalendarModule } from 'primeng/calendar';
import { Ng5SliderModule } from 'ng5-slider';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { TravelDealsLogDetailComponent } from './travel-deals-log-detail/travel-deals-log-detail.component';
export const createTranslateLoader = (http: HttpClient) => {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
};

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        NgSelectModule,
        BrowserAnimationsModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        AppRoutingModule,
        ToastrModule.forRoot({
            timeOut: 5000,
            positionClass: 'toast-top-right'
        }),
        UiSwitchModule,
        Ng2Charts,
        NgxPermissionsModule.forRoot(),
        RecaptchaModule,
        NgxSkeletonLoaderModule,
        NgbModule,
        CalendarModule,
        Ng5SliderModule,
        NgMultiSelectDropDownModule,
        ChartsModule,
        HammerModule
        // environment.production ?
            // ServiceWorkerModule.register('.js', { enabled: environment.production }) : [],

        //  CalendarModule
    ],
    declarations: [AppComponent, TravelDealsLogDetailComponent],
    providers: [AuthGuard, { provide: ErrorHandler, useClass: GlobalErrorHandler }, ScrollTopService,DecimalPipe],
    bootstrap: [AppComponent],
})
export class AppModule { }
