import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'customFilter'
})
export class SearchFilterPipes implements PipeTransform {
    noResult: boolean = false;

    // transform(items: any[], filter: object): any[] {
    //     if (!items || !filter) {
    //         return items;
    //     } else {
    //         items = items.filter(item => item.name.indexOf(filter) !== -1);
    //         if (items.length === 0) {
    //             return [-1];
    //         } else {
    //             return items;
    //         }
    //     }
    // }


    transform(value: any, args?: any): any {
        if (!args) {
            return value;
        }
        return value.filter((val) => {
            let rVal = (val.id.toLocaleLowerCase().includes(args)) || (val.email.toLocaleLowerCase().includes(args));
            return rVal;
        })

    }


}