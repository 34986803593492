// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  // apiURL: "https://api.staging.laytrip.com/v1/",
  // apiURL:"https://api.laytrip.com/v1/",
  apiURL: "https://api.adminredesign.laytrip.com/v1/",

  // apiURL: "http://localhost:4040/v1/",

  // apiURL: "http://localhost:5051/v1/",
  imgAssetsPath: "http://oneclickitmarketing.co.in/laytravel_backend/assets/profile/",
  name: "Default",
  s3BucketUrl: 'https://d2q1prebf1m2s9.cloudfront.net/',
  webLtURL:'https://uat.laytrip.com//',
  webPgURL:'https://uat.pagovoy.com//',
  recaptchKey: '6LcPUboZAAAAAN1Sh411ruqdmT4OD6ZDe65rTsVO',
  cryptoKey: 'H4rdT0Gu3ssP@ssw0rd!'
};
