import { Pipe, PipeTransform } from "@angular/core";
@Pipe({
    name: "customSearch",
})
export class SearchPipe implements PipeTransform {
    transform(value: any, args?: any): any {
        if (!args) {
            return value;
        }
        args = args.toLowerCase();

        return value.filter((val) => {
            let moduleInfo = val.travelPackageItems?.[0]?.moduleInfo;
            let rVal =
                (val.deal_description_en &&
                    val.deal_description_en.toLowerCase().includes(args)) ||
                (val.deal_description_es &&
                    val.deal_description_es.toLowerCase().includes(args)) ||
                (val.deal_description_pt &&
                    val.deal_description_pt.toLowerCase().includes(args)) ||
                (val.platform &&
                    val.platform.toLowerCase().includes(args)) ||
                (moduleInfo?.hotel_name &&
                    moduleInfo?.hotel_name.toLowerCase().includes(args)) ||
                (moduleInfo.address.city_name &&
                    moduleInfo.address.country_name &&
                    `${moduleInfo.address.city_name}, ${moduleInfo.address.country_name}`
                        .toLowerCase()
                        .includes(args)) ||
                (moduleInfo?.title &&
                    moduleInfo?.title.toLowerCase().includes(args)) ||
                (val.travelPackageItems?.[1]?.moduleInfo?.title &&
                    val.travelPackageItems?.[1]?.moduleInfo?.title
                        .toLowerCase()
                        .includes(args));
            return rVal;
        });
    }
}
